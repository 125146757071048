import * as React from "react";
import type { VariantProps } from "tailwind-variants";
import { tv } from "tailwind-variants";

import * as LabelPrimitive from "@radix-ui/react-label";

import { cn } from "~/lib/ui";

const labelVariants = tv({
  base: "text-sm font-medium leading-5 text-stone-900 dark:text-stone-50",
});

const Label = React.forwardRef<
  React.ElementRef<typeof LabelPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root> &
    VariantProps<typeof labelVariants>
>(({ className, ...props }, ref) => (
  <LabelPrimitive.Root
    ref={ref}
    className={cn(labelVariants(), className)}
    {...props}
  />
));
Label.displayName = LabelPrimitive.Root.displayName;

export { Label };
